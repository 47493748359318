import React, { Component, Fragment } from "react";
import EditorMi from "./landings/Editor";
import AppContext from "./contexts/AppContext";
import axios from "axios";
import Loader from 'react-loader-spinner'
import {
   slug,
   getAllLandingDefaults,
   googleAnalytics,
   seoTitle,
   seoDescription,
   seoKeywords,
   facebookPixel,
   setFavicon,
   isNewApp,
   getQueryParams,
} from "utils";
import {
   Headline, Subheadline, Text, Bullet, Button, Image, Video, Divider, FaqElementData, CallActionElementData
} from "utils/elements";
import { ToastContainer, toast } from "react-toastify";
import LoaderSpinner from "./components/LoaderSpiner";
import getUrl, { getApiUrl } from "./utils/getUrl";
import { dragAndDropSections } from "defaults/sections/add_section_with_drag_drop";

class App extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loaded: false,
         landing_name: "",
         landingTitle: '',
         landing: "",
         stringifyLanding: '',
         updatedLanding: null,
         landing_metas: "",
         landing_prices: [],
         authUserRole: "",
         previewMode: false,
         undo: [],
         redo: [],
         firstRender: false,
         id: null,
         save_button: false,
         landing_courses: [],
         landingUrl: null,
         course_id: null,
         landingDefault: getAllLandingDefaults()['land_13'],
         logo: null,
         customLinks: [],
         terms: null,
         privacy: null,
         isModeAdmin: false,
         landingUrlTemp: null,
         course: {},
         isLoading: true,
         isSectionRemoved: false,
         checkout_courses: [],
         user_id: 0,
         editableSectionOrder: null,
         customDomain: 11,
         viewMode: '',
         domains: [
            // {
            //    "id": 11,
            //    "site_uuid": "f775aa08f0db04cdeda85d4eecb6e04d",
            //    "domain": "mymember.site",
            //    "is_domain_pointed": 1,
            //    "is_www_domain_pointed": 1,
            //    "is_https_active": 0,
            //    "is_used": 0,
            //    "is_verficated": 0,
            //    "created_at": "2023-05-05T11:46:59.000000Z",
            //    "updated_at": "2023-05-05T11:46:59.000000Z",
            // },
         ],
         errors: {
            undo: 0,
            redo: 0,
         },
         landingOldUrl: '',
         landingNewUrl: '',
      };
      this.isFirstRender = true;
   }

   componentDidMount() {
      if (window.location.pathname.includes('p')) {
         document.body.style.overflowY = 'auto'
      }
      const domainUrl = getApiUrl()
      let url = getUrl();
      let previewUrl = new URL(url);
      let previewMode = previewUrl.searchParams.get("preview");
      let modeFromUrl = previewUrl.searchParams.get("mode");
      const isCustomDomain = document.querySelector('meta[name="custom_domain"]').content

      if (modeFromUrl === 'admin') {
         this.setState({ isModeAdmin: true });
      }
      let landings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17];
      const newApp = isNewApp(url);
      let token = getQueryParams('token', url);
      // if (url.includes("localhost")) {
      //     this.setState(
      //         {
      //             landing_name: getAllLandingDefaults().land_13["landing_name"],
      //             landing: getAllLandingDefaults().land_13["landing"],
      //             landing_metas: getAllLandingDefaults().land_13["landing_metas"],
      //         },
      //         () => {
      //             this.handleInitialConfigs();
      //         }
      //     );
      // } else
      if (isCustomDomain === 'true') {
         const landingUrl = window.location.hostname;

         axios({
            method: 'get',
            url: ` ${domainUrl}/api/v1/plan-pricings/all`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }).then(response => {

            this.setState(
               {
                  checkout_courses: response.data,
               }

            );

         }).catch(error => {
            console.log(error);
         });
         axios({
            method: 'get',
            url: `${domainUrl}/api/v1/front/p/${landingUrl}`,
            headers: {
               'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
         })
            .then(response => {
               axios({
                  method: 'post',
                  url: ` ${domainUrl}/api/v1/statistics/add-landing-view-count`,
                  headers: {
                     'Authorization': `Bearer ${token}`,
                  },
                  data: {
                     landing_id: response.data.landing_id,
                  },
               }).then(response => {
               }).catch(error => {
                  console.log(error);
               });
               this.setState(
                  {
                     landing_name: response.data.landing_name,
                     landing: response.data.landing,
                     landing_metas: response.data.metas,
                     landing_prices: response.data.pricings,
                     authUserRole: response.data.authUserRole,
                     previewMode: true,
                     landingUrl: landingUrl,
                     landing_courses: response.data.attached_courses,
                     course: response.data.course,
                     isLoading: false,
                     course_id: response.data.course_id,
                     user_id: response.data.user_id,
                     landingTitle: response.data.name,
                  },
                  () => {
                     this.handleInitialConfigs();
                  }
               );
            })
            .catch(error => {
               console.log(error);
               if (error && error.response && (error.response.status === 400 || error.response.status === 404)) {
                  window.location.href = window.location.origin;
               }
            });


         axios({
            method: 'get',
            url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }).then(response => {

            this.setState(
               {
                  customLinks: response.data.custom_links.items,
                  terms: response.data.terms,
                  privacy: response.data.privacy,
                  siteUUid: response.data.site_uuid,
               }

            );

         }).catch(error => {
            console.log(error);
         });
      }
      else if (url.includes("admin/courses")) {
         let url_array = url.split("/");
         //give landing page id
         let landing_page = "land_" + url_array[url_array.length - 2];
         //give default values of current landing page
         let landing = getAllLandingDefaults()[landing_page];
         //make request body current landing page defaults
         let course_id = url_array[url_array.length - 4];
         if (Number.isInteger(parseInt(previewMode))) {
            if (landings.includes(parseInt(previewMode))) {
               this.setState(
                  {
                     landing_name: landing["landing_name"],
                     landing: landing["landing"],
                     landing_metas: landing["landing_metas"],
                     previewMode: true,
                  },
                  () => {
                     this.handleInitialConfigs();
                  }
               );
            } else {
               window.location =
                  "/admin/courses/" + course_id + "/create_step_two";
            }
         } else {
            axios
               .post("", {
                  landing: landing["landing"],
                  metas: landing["landing_metas"],
               })
               .then(response => {
                  this.setState(
                     {
                        landing_name: landing["landing_name"],
                        landing: response.data.landing,
                        landing_metas: response.data.metas,
                        landing_prices: response.data.pricings,
                        authUserRole: response.data.authUserRole,
                     },
                     () => {
                        this.handleInitialConfigs();
                     }
                  );
               })
               .catch(error => {
                  console.log(error);
               });
         }
      }

      ///////////////////////////////////////////ADMIN
      else if (newApp && getQueryParams('mode', url) === 'admin') {
         let landingUrl = getQueryParams('url', url);
         let landing = getAllLandingDefaults()['land_13'];
         //make request body current landing page defaults
         if (Number.isInteger(parseInt(previewMode))) {
            if (landings.includes(parseInt(previewMode))) {
               this.setState(
                  {
                     landing_name: landing["landing_name"],
                     landing: landing["landing"],
                     landing_metas: landing["landing_metas"],
                     previewMode: true,
                     landingUrl: landingUrl,
                  },
                  () => {
                     this.handleInitialConfigs();
                  }
               );
            } else {
               // window.location =
               //     "/admin/courses/" + course_id + "/edit#course-material";
            }
         } else {

            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/plan-pricings/all`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     checkout_courses: response.data,
                  }

               );

            }).catch(error => {
               console.log(error);
            });

            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {
               let landingNewDefault = { ...this.state.landingDefault };
               if (response.data.school_logo) {
                  if (landingNewDefault["landing"][0].components[0].subcomponents) {
                     landingNewDefault["landing"][0].components[0].subcomponents[0].props.imgUrl = response.data.school_logo;
                  } else {
                     landingNewDefault["landing"][0].components[0].props.imgUrl = response.data.school_logo;
                  }
               }
               if (landingNewDefault["landing"][0].components[0].subcomponents) {
                  landingNewDefault["landing"][0].components[0].subcomponents[0].props.text = response.data.title ? response.data.title : 'Your Logo';
               } else {
                  landingNewDefault["landing"][0].components[0].props.text = response.data.title ? response.data.title : 'Your Logo';
               }
               this.setState(
                  {
                     logo: response.data.school_logo,
                     customLinks: response.data.custom_links.items,
                     terms: response.data.terms,
                     privacy: response.data.privacy,
                     siteUUid: response.data.site_uuid,
                     landingUrl: landingUrl,
                     landingDefault: landingNewDefault,
                  }, () => {
                     axios({
                        method: 'get',
                        url: `${domainUrl}/api/v1/landings/get/${landingUrl}`,
                        headers: {
                           'Authorization': `Bearer ${token}`,
                        },
                     }).then(response => {

                        this.setState(
                           {
                              landingNewUrl: response.data.url,
                              landingOldUrl: response.data.url
                           });

                        let landingSections = response.data.landing;
                        let HeaderIndex = null;
                        let FirstIndex = null;
                        let SecondIndex = null;
                        let ThirdIndex = null;
                        let PricingIndex = null;
                        let FooterIndex = null;
                        let CourseIndex = null;
                        let BundleIndex = null;
                        let domainId = response.data.check_domain
                        // axios({
                        //    method: 'get',
                        //    url: ` ${domainUrl}/api/v1/landings/check/${response.data.landing_id}`,
                        //    headers: {
                        //       'Authorization': `Bearer ${token}`,
                        //    },
                        // }).then(response => {
                        //    domainId = response.data ? response.data.id : null

                        //    }).catch(error => {
                        //       console.log(error);
                        //       });
                        if (domainId) {
                           axios({
                              method: 'get',
                              url: ` ${domainUrl}/api/v1/landings/get-domains`,
                              headers: {
                                 'Authorization': `Bearer ${token}`,
                              },
                           }).then(response => {

                              this.setState(
                                 {
                                    domains: response.data.filter((e) => e.is_used !== 1 || e.id === domainId),
                                    landingUrlType: domainId ? 'pointed' : 'custom',
                                    customDomain: domainId,
                                 }

                              );

                           }).catch(error => {
                              console.log(error);
                           });
                        }

                        for (let i = 0; i <= landingSections.length - 1; i++) {
                           if (landingSections[i].section.props.duplicated === 'header') {
                              HeaderIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 1) {
                              FirstIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 2) {
                              SecondIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 3) {
                              ThirdIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 'pricing') {
                              PricingIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 'footer') {
                              FooterIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 4) {
                              BundleIndex = i;
                           }
                           if (landingSections[i].section.props.duplicated === 'course') {
                              CourseIndex = i;
                           }
                        }
                        if (HeaderIndex !== null && landingSections[HeaderIndex] &&
                           !landingSections[HeaderIndex].section.props.backgroundLabel) {
                           landingSections[HeaderIndex].section.props.backgroundLabel = 'Background Image (1900x80)';
                        }
                        if (HeaderIndex !== null && landingSections[HeaderIndex] &&
                           !landingSections[HeaderIndex].components[0].props.imageLabel) {
                           landingSections[HeaderIndex].components[0].props.imageLabel = 'Show Image (200x60)';
                        }
                        if (FirstIndex !== null && landingSections[FirstIndex] &&
                           !landingSections[FirstIndex].section.props.backgroundLabel) {
                           landingSections[FirstIndex].section.props.backgroundLabel = 'Background Image (1900x600)';
                        }
                        if (SecondIndex !== null && landingSections[SecondIndex] &&
                           !landingSections[SecondIndex].section.props.backgroundLabel) {
                           response.data.landing[SecondIndex].section.props.backgroundLabel = 'Background Image (1900x415)';
                        }
                        if (SecondIndex !== null && landingSections[SecondIndex] &&
                           landingSections[SecondIndex].components.length &&
                           !landingSections[SecondIndex].components[0].props.imageLabel) {
                           response.data.landing[SecondIndex].components[0].props.imageLabel = 'Show Image (600x400)';
                        }
                        if (ThirdIndex !== null && landingSections[ThirdIndex] &&
                           !landingSections[ThirdIndex].section.props.backgroundLabel) {
                           landingSections[ThirdIndex].section.props.backgroundLabel = 'Background Image (1900x500)';
                        }
                        if (ThirdIndex !== null && landingSections[ThirdIndex] &&
                           landingSections[ThirdIndex].components.length &&
                           !landingSections[ThirdIndex].components[0].props.imageLabel) {
                           landingSections[ThirdIndex].components[0].props.imageLabel = 'Show Image (600x400)';
                        }
                        if (PricingIndex !== null && landingSections[PricingIndex] &&
                           !landingSections[PricingIndex].section.props.backgroundLabel) {
                           response.data.landing[PricingIndex].section.props.backgroundLabel = 'Background Image (1900x400)';
                        }
                        if (!response.data.metas.isWithLinks &&
                           response.data.metas.isCustomLinks && HeaderIndex !== null &&
                           landingSections[HeaderIndex] && landingSections[HeaderIndex].components.length > 2) {
                           landingSections[HeaderIndex].components.splice(1, 4);
                        }
                        if (!response.data.metas.isWithLinks && FooterIndex !== null &&
                           landingSections[FooterIndex] && landingSections[FooterIndex].components.length === 4) {
                           landingSections[FooterIndex].components.shift();
                        }
                        if (BundleIndex !== null && landingSections[BundleIndex] &&
                           landingSections[BundleIndex].section.props &&
                           response.data.attached_courses && response.data.attached_courses.length === 0) {
                           landingSections[BundleIndex].section.props.isNull = true;
                        }
                        if (BundleIndex !== null && landingSections[BundleIndex] &&
                           landingSections[BundleIndex].section.props &&
                           response.data.attached_courses && response.data.attached_courses.length !== 0) {
                           landingSections[BundleIndex].section.props.isNull = false;
                        }
                        this.setState(
                           {
                              landing_name: landing["landing_name"],
                              landing: response.data.landing,
                              stringifyLanding: JSON.stringify(response.data.landing),
                              landing_metas: response.data.metas,
                              landing_prices: response.data.pricings,
                              authUserRole: response.data.authUserRole,
                              course_id: response.data.course_id,
                              id: response.data.landing_id,
                              landing_courses: response.data.attached_courses,
                              course: response.data.course,
                              isLoading: false,
                              user_id: response.data.user_id,
                              landingTitle: response.data.name,
                           },
                           () => {
                              this.handleInitialConfigs();
                           }
                        );
                     })
                        .catch(error => {
                           console.log(error);
                        });

                  }

               );

            })
               .catch(error => {
                  console.log(error);
               });
         }
      }

      ////////////////////////////////////MEMBER
      else if (newApp && getQueryParams('mode', url) === 'member') {
         const courseUrl = getQueryParams('course_url', url);
         const landingUrl = getQueryParams('landing_url', url);

         axios({
            method: 'get',
            url: ` ${domainUrl}/api/v1/plan-pricings/all`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }).then(response => {
            this.setState(
               {
                  checkout_courses: response.data,
               }

            );

         }).catch(error => {
            console.log(error);
         });
         axios({
            method: 'get',
            url: `${domainUrl}/api/v1/front/p/${landingUrl}`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         })
            .then(response => {
               axios({
                  method: 'post',
                  url: ` ${domainUrl}/api/v1/statistics/add-landing-view-count`,
                  headers: {
                     'Authorization': `Bearer ${token}`,
                  },
                  data: {
                     landing_id: response.data.landing_id,
                  },
               }).catch(error => {
                  console.log(error);
               });

               this.setState(
                  {
                     landing_name: response.data.landing_name,
                     landing: response.data.landing,
                     landing_metas: response.data.metas,
                     landing_prices: response.data.pricings,
                     authUserRole: response.data.authUserRole,
                     previewMode: true,
                     landingUrl: landingUrl,
                     landing_courses: response.data.attached_courses,
                     course: response.data.course,
                     isLoading: false,
                     course_id: response.data.course_id,
                     user_id: response.data.user_id,
                     landingTitle: response.data.name,
                  },
                  () => {
                     this.handleInitialConfigs();
                  }
               );
            })
            .catch(error => {
               if (error && error.response && (error.response.status === 400 || error.response.status === 404)) {
                  window.location.href = window.location.origin;
               }
            });


         axios({
            method: 'get',
            url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }).then(response => {

            this.setState(
               {
                  customLinks: response.data.custom_links.items,
                  terms: response.data.terms,
                  privacy: response.data.privacy,
                  siteUUid: response.data.site_uuid,
               }

            );

         }).catch(error => {
            console.log(error);
         });
      }
      /////////////////////////////////////////////

      else {
         const url = window.location.pathname.split('/');
         const landingUrl = window.location.hostname;
         let previewUrl = window.location.href;
         if (url[1] === 'temp-landing') {
            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/plan-pricings/all`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     checkout_courses: response.data,
                  }

               );

            }).catch(error => {
               console.log(error);
            });

            if (window.currentMetas) {
               const body = document.querySelector('body');
               const head = document.querySelector('head');
               if (window.currentMetas.after_opening_body_tag) {
                  body.insertAdjacentHTML('afterbegin', window.currentMetas.after_opening_body_tag);
               }
               if (window.currentMetas.before_closing_body_tag) {
                  body.insertAdjacentHTML('beforeend', window.currentMetas.before_closing_body_tag);
               }
               if (window.currentMetas.head_section_tracking_codes) {
                  head.insertAdjacentHTML('beforeend', window.currentMetas.head_section_tracking_codes);
               }
            }
            this.setState(
               {
                  landing_name: window.landing_name,
                  landing: window.currentLanding,
                  landing_metas: window.currentMetas,
                  authUserRole: window.authUserRole,
                  previewMode: true,
                  landingUrl: landingUrl,
                  landing_courses: window.landing_courses,
                  course: window.course,
                  isLoading: false,
                  course_id: window.course_id,
                  user_id: window.user_id,
                  landingTitle: window.landingTitle,
               },
               () => {
                  this.handleInitialConfigs();
               }

            );

            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     customLinks: response.data.custom_links.items,
                     terms: response.data.terms,
                     privacy: response.data.privacy,
                     siteUUid: response.data.site_uuid,
                  }

               );

            })
               .catch(error => {
                  console.log(error);
               });
         }
         else if (getQueryParams('preview', previewUrl)) {
            let templateId = getQueryParams('templateId', previewUrl);
            axios({
               method: 'get',
               url: `${domainUrl}/api/v1/landings/templates/${templateId}`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            })
               .then(response => {
                  this.setState(
                     {
                        landing_name: response.data.landing_name,
                        landing: response.data.landing,
                        landing_metas: response.data.metas,
                        previewMode: true,
                        isLoading: false,
                        course_id: 0,
                     },
                     () => {
                        this.handleInitialConfigs();
                     }
                  );
               })
               .catch(error => {
                  if (error && error.response && (error.response.status === 400 || error.response.status === 404)) {
                     window.location.href = window.location.origin;
                  }
               });


            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     customLinks: response.data.custom_links.items,
                     terms: response.data.terms,
                     privacy: response.data.privacy,
                     siteUUid: response.data.site_uuid,
                  }

               );

            }).catch(error => {
               console.log(error);
            });

         }
         else {
            const url = window.location.pathname.split('/');
            const landingUrl = url[2];

            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/plan-pricings/all`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     checkout_courses: response.data,
                  }

               );

            }).catch(error => {
               console.log(error);
            });

            axios({
               method: 'get',
               url: `${domainUrl}/api/v1/front/p/${landingUrl}`,
               headers: {
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
               },
            })
               .then(response => {
                  axios({
                     method: 'post',
                     url: ` ${domainUrl}/api/v1/statistics/add-landing-view-count`,
                     headers: {
                        'Authorization': `Bearer ${token}`,
                     },
                     data: {
                        landing_id: response.data.landing_id,
                     },
                  }).catch(error => {
                     console.log(error);
                  });
                  this.setState(
                     {
                        landing_name: response.data.landing_name,
                        landing: response.data.landing,
                        landing_metas: response.data.metas,
                        landing_prices: response.data.pricings,
                        authUserRole: response.data.authUserRole,
                        previewMode: true,
                        landingUrl: landingUrl,
                        landing_courses: response.data.attached_courses,
                        course: response.data.course,
                        isLoading: false,
                        course_id: response.data.course_id,
                        user_id: response.data.user_id,
                        landingTitle: response.data.name,
                     },
                     () => {
                        this.handleInitialConfigs();
                     }
                  );
               })
               .catch(error => {
                  if (error && error.response && (error.response.status === 400 || error.response.status === 404)) {
                     window.location.href = window.location.origin;
                  }
               });


            axios({
               method: 'get',
               url: ` ${domainUrl}/api/v1/front/site-builder-landing`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }).then(response => {

               this.setState(
                  {
                     customLinks: response.data.custom_links.items,
                     terms: response.data.terms,
                     privacy: response.data.privacy,
                     siteUUid: response.data.site_uuid,
                  }

               );

            }).catch(error => {
               console.log(error);
            });
         }
      }
      //////////////////////////////////////////
      window.addEventListener('load', this.handleLoad);
      window.onload = () => {
         setTimeout(() => {
            this.setState({
               loaded: true,
            })
         }, 1000)
      }
   }

   componentDidUpdate(prevState, prevProps) {
      if (this.isFirstRender) {
         this.isFirstRender = false;
         return
      } else if (Array.isArray(this.state.landing) && this.state.stringifyLanding !== JSON.stringify(this.state.landing)) {
         window.parent.postMessage({ type: 'unsavedChanges', hasUnsavedChanges: true }, '*');
      }
   }

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////// end

   handleInitialConfigs = () => {
      if (this.state.landing_metas) {
         // SET FAVICON TO LANDING PAGE TITLE WHEN PAGE IS LOADED
         setFavicon(this.state.landing_metas.landing_favicon);
         // SET FACEBOOK PIXEL ON PAGE
         const fb_pixel_code = this.state.landing_metas.fb_pixel_code;
         //INSERT FACEBOOK PIXEL ID
         facebookPixel(fb_pixel_code)
         // SET GOOGLE ANALYTICS ON PAGE
         const ga_code = this.state.landing_metas.google_anal_resource_id;
         //INSERT GOOGLE ANALYTICS  ID
         googleAnalytics(ga_code);
         // SET SEO IN PAGE
         const seo_title = this.state.landing_metas.seo_title;
         const seo_description = this.state.landing_metas.seo_description;
         const seo_keywords = this.state.landing_metas.seo_keywords;
         //INSERT SEO OPTIMIZATION TITLE,KEYWORDS
         // SET SEO TITLE
         seoTitle(seo_title ? seo_title : this.state.landingTitle);
         // SET SEO DESCRIPTION
         seoDescription(seo_description);
         // SET SEO KEYWORDS
         seoKeywords(seo_keywords);
      } else {
         window.location.href = window.location.origin;
      }

   };

   handleUpdateTempLanding = () => {
      let url = window.location.href;
      let url_array = url.split("/");

      const newApp = isNewApp(url);
      const token = getQueryParams('token', url);
      // GIVE COURSE ID
      // let course_id = url_array[url_array.length - 4];
      let landingUrl = getQueryParams('url', url);
      if (newApp) {
         //course_id = getQueryParams('courseId', url);

      }
      const win = window.open(`/temp-landing/${landingUrl}`, "_blank");
      win.currentLanding = this.state.landing;
      win.currentMetas = this.state.landing_metas;
      win.landing_name = this.state.landing_name;
      win.authUserRole = this.state.authUserRole;
      win.landing_courses = this.state.landing_courses;
      win.course = this.state.course;
      win.course_id = this.state.course_id;
      win.user_id = this.state.user_id;
      win.landingTitle = this.state.landingTitle
   }

   handleUpdateLanding = type => {
      let url = window.location.href;
      let url_array = url.split("/");

      const newApp = isNewApp(url);
      const token = getQueryParams('token', url);
      // GIVE COURSE ID
      let course_id = url_array[url_array.length - 4];
      // REMOVE SPACINGS FROM KEYWORDS
      let separated_keywords = this.state.landing_metas.seo_keywords;
      let seo_keywords_array = [];
      if (separated_keywords) {
         if (separated_keywords.indexOf(",") > -1) {
            separated_keywords = separated_keywords.split(",");
            separated_keywords.forEach(element => {
               element = element
                  .replace(/^\s+|\s+$/gm, "")
                  .replace(/\s\s+/g, " ");
               seo_keywords_array.push(element);
            });
            let trimmed_keywords = seo_keywords_array.join();
            if (trimmed_keywords[trimmed_keywords.length - 1] === ",") {
               trimmed_keywords = trimmed_keywords.substring(
                  0,
                  trimmed_keywords.length - 1
               );
            }
            this.state.landing_metas.seo_keywords = trimmed_keywords;
         } else {
            this.state.landing_metas.seo_keywords = separated_keywords
               .replace(/^\s+|\s+$/gm, "")
               .replace(/\s\s+/g, " ");
         }
      }
      // TEST IN LOCALHOST
      if (url.includes("localhost")) {
         toast.error("Any action in localhost don't work!");
         this.setState({
            undo: [],
            redo: [],
            firstRender: false,
         });
      }
      // ON SERVER
      else if (newApp) {
         const domainUrl = getApiUrl()
         this.setState({ save_button: true });
         let course_id = getQueryParams('courseId', url);
         let landingUrl = getQueryParams('url', url);

         let data = {
            landing: this.state.landing,
            metas: this.state.landing_metas,
            name: this.state.landingTitle,
            url: this.state.landingNewUrl,
         };

         if (this.state.landingOldUrl === this.state.landingNewUrl || this.state.landingNewUrl === '') {
            data = {
               landing: this.state.landing,
               metas: this.state.landing_metas,
               name: this.state.landingTitle,
            };
            this.setState({ landingNewUrl: this.state.landingOldUrl });
         }

         axios({
            method: 'put',
            url: `${domainUrl}/api/v1/landings/update/${landingUrl}`,
            data,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }).then(response => {
            this.setState(
               {
                  undo: [],
                  redo: [],
                  firstRender: false,
               },
               () => {
                  if (type === "save") {
                     toast.success("SAVED");
                     this.setState({ save_button: false });
                  } else if (type === "next") {
                     window.top.location.href = window.location.origin + "/admin/landings";
                  }
               }
            );

         })
            .catch(error => {
               console.log(error);
               this.setState({ save_button: false });
               if (error.response && error.response.data && error.response.data.message) {
                  toast.error(error.response.data.message);
               }
            });
      } else {
         this.setState({ save_button: true });
         axios
            .post("update", {
               landing: this.state.landing,
               metas: this.state.landing_metas,
            })
            .then(response => {
               this.setState(
                  {
                     // landing: response.data.landing,
                     // landing_metas: response.data.metas,
                     undo: [],
                     redo: [],
                     firstRender: false,
                  },
                  () => {
                     if (type === "save") {
                        toast.success("SAVED");
                        this.setState({ save_button: false });
                     } else if (type === "next") {
                        window.location =
                           "/admin/courses/" +
                           course_id +
                           "/course-checkout";
                     }
                  }
               );
            })
            .catch(error => {
               console.log(error);
            });
      }
   };

   handleIsPrint = (text) => {
      let errors = document.getElementsByClassName('Toastify__toast-body')
      for (let i = 0; i < errors.length; i++) {
         if (errors[i].innerHTML === text) {
            return false
         }
      }
      return true
   }

   // UNDO AND REDO FUNCTIONALITY
   handleUndoRedo = (type) => {
      if (type === 'undo') {
         if (this.state.undo.length > 0) {
            this.state.redo.push(JSON.stringify(this.state.landing))
            this.setState({
               landing: JSON.parse(this.state.undo[this.state.undo.length - 1]),
            })
            this.state.undo.pop()
         } else if (this.handleIsPrint('Nothing to undo')) {
            toast.error('Nothing to undo')
         }
      } else if (type === 'redo') {
         if (this.state.redo.length > 0) {
            this.state.undo.push(JSON.stringify(this.state.landing))
            this.setState({
               landing: JSON.parse(this.state.redo[this.state.redo.length - 1]),
            })
            this.state.redo.pop()
         } else if (this.handleIsPrint('Nothing to redo')) {
            toast.error('Nothing to redo')
         }
      }
   };

   handleUpdateProps = (sectionOrder, slug, value, props_name, undoRedo = null, propsName) => {
      let landing = { ...this.state.landing };
      let updatedLanding = { ...this.state.updatedLanding };
      let currentLanding = {
         currentLanding: JSON.parse(JSON.stringify(landing)),
         sectionOrder: sectionOrder,
         slug: slug,
         props_name: props_name,
         function: 'handleUpdateProps',
      };
      this.state.undo.push(JSON.stringify(this.state.landing))
      if (landing[sectionOrder]?.section.slug === slug) {
         // CHECK FIRST TIME CALL UPDATEPROPS TO ADD INITIAL OBJECT TO UNDO ARRAY
         if (!this.state.firstRender) {
            // landing = Object.values(landing);
            if (undoRedo !== 'undo' && undoRedo !== 'redo') {
               if (props_name === 'image') {
                  currentLanding.value = landing[sectionOrder].section.props.bgImgSrc;
               } else {
                  currentLanding.value = landing[sectionOrder].section.props[props_name];
               }
               // currentLanding = JSON.parse(JSON.stringify(currentLanding));
               // undo.push(currentLanding);
               // undo = Object.values(undo);
            }
            //
         } else {
            // CHECK IF NEW CLICKED COMPONENT IS DIFFERENT OR PROPS NAME IS DIFFERENT TO ADD NEW OBJECT TO UNDO ARRAY
            if (undoRedo !== 'undo' || undoRedo !== 'redo ') {
               if (slug !== this.state.undo[this.state.undo.length - 1].slug || props_name !== this.state.undo[this.state.undo.length - 1].props_name) {
                  if (props_name === 'image') {
                     currentLanding.value = landing[sectionOrder].section.props.bgImgSrc;
                  } else {
                     currentLanding.value = landing[sectionOrder].section.props[props_name];
                  }
                  // currentLanding = JSON.parse(JSON.stringify(currentLanding));
                  // undo.push(currentLanding);
                  // undo = Object.values(undo);
               }
            }
         }
         if (props_name === 'image') {
            landing[sectionOrder].section.props.bgImgSrc = value;
            landing[sectionOrder].section["ImageProcessor"] = true;
         } else {
            landing[sectionOrder].section.props[props_name] = value;
         }
      } else {
         if ((sectionOrder || sectionOrder === 0) && this.state.landing_metas.dragAndDropLanding) {
            landing[sectionOrder].components.forEach((maincomponent, i) => {
               if (['Group', 'Group2', 'Group-Pricing', 'bgImgEditable', 'bgColorEditable'].includes(maincomponent.props.isType) ) {
                  if (maincomponent.slug === slug) {
                     // CHECK FIRST TIME CALL UPDATEPROPS TO ADD INITIAL OBJECT TO UNDO ARRAY
                     if (!this.state.firstRender) {
                        // landing = Object.values(landing);
                        if (undoRedo !== 'undo' && undoRedo !== 'redo') {
                           currentLanding.value = maincomponent.props[props_name];
                           // currentLanding = JSON.parse(JSON.stringify(currentLanding));
                           // undo.push(currentLanding);
                           // undo = Object.values(undo);
                        }
                        //
                     }
                     // SETS COMPONENT VALUE
                     if (props_name === 'linked') {
                        maincomponent.props['scroll_to_section'] = !value;
                     } else {
                        if(propsName === 'bgImgSrc') {
                           maincomponent.props[propsName] = value;
                           maincomponent["ImageProcessor"] = true;
                        } else if (props_name === 'image') {
                           maincomponent.props.imgUrl = value;
                           maincomponent["ImageProcessor"] = true;
                        } else {
                           maincomponent.props[props_name] = value;
                        }
                     }
                     if (props_name === "width" && maincomponent.type === "Video") {
                        if (undoRedo !== 'undo' || undoRedo !== 'redo ') {
                           if (slug !== this.state.undo[this.state.undo.length - 1].slug || props_name !== this.state.undo[this.state.undo.length - 1].props_name) {
                              // let undo = this.state.undo;
                              currentLanding.value = maincomponent.props["height"];
                              // currentLanding = JSON.parse(JSON.stringify(currentLanding));
                              // undo.push(currentLanding);
                              // undo = Object.values(undo);
                           }
                        }
                        maincomponent.props["height"] = Math.round(
                           (value * 9) / 16
                        );
                     }
                     //
                  }
               }
               maincomponent.subcomponents.forEach((component) => {
                  if (component.slug === slug) {
                     // CHECK FIRST TIME CALL UPDATEPROPS TO ADD INITIAL OBJECT TO UNDO ARRAY
                     if (!this.state.firstRender) {
                        // landing = Object.values(landing);
                        if (undoRedo !== 'undo' && undoRedo !== 'redo') {
                           if (props_name === 'image') {
                              currentLanding.value = component.props.imgUrl;
                           } else {
                              currentLanding.value = component.props[props_name];
                           }
                           // currentLanding = JSON.parse(JSON.stringify(currentLanding));
                           // undo.push(currentLanding);
                           // undo = Object.values(undo);
                        }
                        //
                     }
                     // SETS COMPONENT VALUE
                     if (props_name === 'linked') {
                        component.props['scroll_to_section'] = !value;
                     } else {
                        if (props_name === 'image') {
                           if(propsName) {
                              component.props[propsName].imgUrl = value;
                              component["ImageProcessor"] = true;
                           } else {
                              component.props.imgUrl = value;
                              component["ImageProcessor"] = true;
                           }

                        } else {
                           if (component.avatarWithText && propsName) {
                              component.props[propsName][props_name] = value;
                           } else {
                              component.props[props_name] = value;
                           }

                        }
                     }
                     if (props_name === "width" && component.type === "Video") {
                        component.props["height"] = Math.round(
                           (value * 9) / 16
                        );
                     }
                     //
                  }
               });
            });
         } else if (sectionOrder || sectionOrder === 0) {
            landing[sectionOrder].components.forEach((component, i) => {
               if (component.slug === slug) {
                  if (props_name === 'linked') {
                     component.props['scroll_to_section'] = !value;
                  } else {
                     if (props_name === 'image') {
                        component.props.imgUrl = value;
                        component["ImageProcessor"] = true;
                     } else {
                        component.props[props_name] = value;
                     }
                  }
                  if (props_name === "width" && component.type === "Video") {
                     component.props["height"] = Math.round(
                        (value * 9) / 16
                     );
                  }
                  //
               }
            });
         }
      }
      if (sectionOrder) {
         updatedLanding[sectionOrder] = {
            section: landing[sectionOrder].section,
            components: landing[sectionOrder].components,
         };
      }
      updatedLanding = Object.values(updatedLanding);
      landing = Object.values(landing);
      //  CHECK IF handleUpdateProps FUNCTION WAS CALLED WHEN COMPONENT CHANGES
      if (undoRedo !== 'undo' && undoRedo !== 'redo') {
         let undo = this.state.undo;
         let currentLanding = {
            currentLanding: JSON.parse(JSON.stringify(landing)),
            sectionOrder: sectionOrder,
            slug: slug,
            value: value,
            props_name: props_name,
            function: 'handleUpdateProps',
         };
         currentLanding = JSON.parse(JSON.stringify(currentLanding));
         this.setState({
            landing,
            updatedLanding,
            firstRender: true,
         },
            () => {

            });
      }
      //
      //  CHECK IF handleUpdateProps FUNCTION WAS CALLED FROM handleUndoRedo
      else if (undoRedo == 'undo' || undoRedo == 'redo') {
         this.setState(
            {
               landing,
               updatedLanding,
               firstRender: true,
            }
         );
      }


   };

   handleSectionOptions = (sectionOrder, option, value) => {
      this.state.undo.push(JSON.stringify(this.state.landing))
      let landing = { ...this.state.landing };
      let updatedLanding = { ...this.state.updatedLanding };
      let undo;
      let currentLanding;
      // CHECK AND ADD INITIAL LANDING TO UNDO ARRAY WHEN CHANGE IS FIRST TIME
      if (!this.state.firstRender) {
         undo = this.state.undo;
         currentLanding = {
            currentLanding: JSON.parse(JSON.stringify(landing)),
            slug: 'handleSectionOptions',
            props_name: 'handleSectionOptions',
            function: 'handleSectionOptions',
         };
         this.setState({ firstRender: true });

      }
      // CHECK IF NEW CLICKED COMPONENT IS DIFFERENT OR PROPS NAME IS DIFFERENT TO ADD NEW OBJECT TO UNDO ARRAY
      if (this.state.undo[this.state.undo.length - 1].slug !== 'handleSectionOptions' || this.state.undo[this.state.undo.length - 1].props_name !== 'handleSectionOptions') {
         undo = this.state.undo;
         currentLanding = {
            currentLanding: JSON.parse(JSON.stringify(landing)),
            slug: 'handleSectionOptions',
            props_name: 'handleSectionOptions',
            function: 'handleSectionOptions',
         };
      }
      if (landing[sectionOrder]) {
         switch (option) {
            case "rename":
               landing[sectionOrder].section.name = value;
               break;
            case "hide":
               landing[sectionOrder].section.props.visibility = value;
               break;
            case "duplicate":
               let duplicatedSection = JSON.parse(
                  JSON.stringify(landing[sectionOrder])
               );
               if (duplicatedSection.section.id) {
                  duplicatedSection.section.id = null;
               }
               duplicatedSection.section.props.duplicated =
                  duplicatedSection.section.props.duplicated;
               duplicatedSection.section.slug = slug();
               duplicatedSection.section.props.order = Object.keys(
                  landing
               ).length - 1;
               duplicatedSection.components.map((component, i) => {
                  component.slug = slug();
                  component.id = null;
                  if (!component.type) {
                     component.type = 'component';
                  }
               });
               const footer = landing[Object.keys(landing).length - 1];
               footer.section.props.order = Object.keys(landing).length;
               landing[Object.keys(landing).length - 1] = duplicatedSection;
               landing[Object.keys(landing).length] = footer;
               setTimeout(() => {
                  document.getElementById(duplicatedSection.section.slug).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
               }, 100);
               break;
            case "delete":
               landing[sectionOrder].section.props["deleted"] = true;
               break;
            default:
               break;
         }
      }
      updatedLanding[sectionOrder] = {
         section: landing[sectionOrder].section,
         components: landing[sectionOrder].components,
      };
      // ADD LANDING INTO UNDO ARRAY
      undo = this.state.undo;
      landing = Object.values(landing);
      currentLanding = {
         currentLanding: JSON.parse(JSON.stringify(landing)),
         slug: 'handleSectionOptions',
         props_name: 'handleSectionOptions',
         function: 'handleSectionOptions',
      };
      this.setState({
         landing,
         updatedLanding,
      }, () => {

      });
   }

   //    LOGIC TO ADD NEW SECTION FROM DEFAULTS
   handleAddSection = (section_default_json, section_default_slug = null, currentIndex = -1) => {
      // Create a deep copy of the landing array
      const stringLandingData = JSON.stringify(this.state.landing);
      const newLanding = JSON.parse(stringLandingData);

      const newSection = JSON.parse(JSON.stringify(section_default_json));

      // ADD SECTION INTO UNDO ARRAY
      this.state.undo.push(stringLandingData)
      this.setState({ isSectionRemoved: true });

      if (currentIndex === -1) {
         newLanding[newLanding.length - 1].section.props.order = newLanding.length;
         newSection.section.props.order = newLanding.length - 1;

         newLanding.splice(newLanding.length - 1, 0, newSection);
      } else {
         newSection.section.props.order = currentIndex + 1;

         newLanding.splice(currentIndex + 1, 0, newSection);

         for (let i = currentIndex + 2; i <= newLanding.length - 1; i++) {
            newLanding[i].section.props.order = i;
         }
      }

      this.setState({ landing: newLanding }, () => {
         // SCROLL TO NEW ADDED SECTION
         const added_section = document.querySelector(
            "#" + section_default_slug
         );

         if (added_section) {
            added_section.scrollIntoView({ behavior: "smooth" });
            toast.success("The section has been added.");
         }
      });
   };

   //    LOGIC TO ADD NEW LINK COMPONENT
   handleAddLinkComponent = () => {
      if (document.querySelectorAll(".SectionComponent")) {
         document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
         document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
         document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
      }
      let landing = this.state.landing;
      let newLinkComponent =
      {
         slug: slug(),
         type: 'LinkMi',
         name: 'Link',
         props: {
            text: 'Default Text',
            bgColor: '#7cb740',
            textColor: '#ffffff',
            href: '',
            scroll_to: '',
            size: 'medium',
            fontFamily: 'Roboto-Bold',
            fontStyle: 'bold',
            textSize: 18,
            allCaps: false,
            visibility: true,
            deleted: false,
            target: '_self',
            oldLinks: true,
            dividerType: "",
         },
      };

      landing[0].components.splice(this.state.landing[0].components.length, 0, newLinkComponent);
      // ADD SECTION INTO UNDO ARRAY
      let undo = this.state.undo;
      let currentLanding = JSON.parse(JSON.stringify(this.state.landing));
      this.state.undo.push(JSON.stringify(this.state.landing))
      this.setState({ landing: currentLanding }, () => {
      });
   };
   //--------------------------------------------------------------------------------------------
   // logic to add link subcomponent
   handleAddLinkSubComponent = (isFooterLink, landingId) => {
      if (document.querySelectorAll(".SectionComponent")) {
         // document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
         document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
         document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
      }
      let landing = this.state.landing;
      let newLinkComponent =
      {
         slug: slug(),
         type: 'LinkMi',
         name: 'Link',
         props: {
            text: 'Default Text',
            bgColor: '#7cb740',
            textColor: '#ffffff',
            href: '',
            scroll_to: '',
            size: 'medium',
            fontFamily: 'Roboto-Bold',
            fontStyle: 'bold',
            textSize: 18,
            allCaps: false,
            visibility: true,
            deleted: false,
            target: '_self',
            availableDelete: true,
         },
      };
      if (isFooterLink) {
         if (landingId == 'landing_19') {
            newLinkComponent =
            {
               slug: slug(),
               type: 'LinkMi',
               name: 'Link',
               props: {
                  text: 'Default Text',
                  bgColor: '#ffffff',
                  textColor: '#464646',
                  href: '',
                  scroll_to: 'another_page',
                  size: 'medium',
                  fontFamily: 'Lato-Bold',
                  fontStyle: 'normal',
                  textSize: 14,
                  allCaps: false,
                  visibility: true,
                  deleted: false,
                  isFooterLink: true,
                  target: '_blank',
                  availableDelete: true,
               },
            };
         } else {
            newLinkComponent =
            {
               slug: slug(),
               type: 'LinkMi',
               name: 'Link',
               props: {
                  text: 'Default Text',
                  bgColor: '#7cb740',
                  textColor: '#ffffff',
                  href: '',
                  scroll_to: 'another_page',
                  size: 'medium',
                  fontFamily: 'Roboto-Regular',
                  fontStyle: 'normal',
                  textSize: 16,
                  allCaps: false,
                  visibility: true,
                  deleted: false,
                  isFooterLink: true,
                  target: '_blank',
                  availableDelete: true,
               },
            };
         }

         landing[landing.length - 1].components[1].subcomponents.splice(this.state.landing[landing.length - 1].components[1].subcomponents.length, 0, newLinkComponent);
      } else {
         landing[0].components[1].subcomponents.splice(this.state.landing[0].components[1].subcomponents.length - 1, 0, newLinkComponent);
      }


      // ADD SECTION INTO UNDO ARRAY
      let undo = this.state.undo;
      this.state.undo.push(JSON.stringify(this.state.landing))
      let currentLanding = JSON.parse(JSON.stringify(this.state.landing));
      this.setState({ landing: currentLanding });
   }
   //----------------------------------------------------------------------------------------
   //---- Delete Component
   handleDeleteComponent = (sectionOrder, slug, value, props_name, isView) => {
      this.state.undo.push(JSON.stringify(this.state.landing))
      let landing = [...this.state.landing];
      if (isView) {
         let currentSection = landing.filter((sections) => sections.section.slug === sectionOrder);
         currentSection && currentSection[0].components.forEach((maincomponent, i) => {
            maincomponent.subcomponents.forEach((component, j) => {
               if (component.slug === slug) {
                  currentSection[0].components[i].subcomponents && currentSection[0].components[i].subcomponents.splice(j, 1);
                  this.setState({ landing });
                  if (document.querySelectorAll(".SectionComponent")) {
                     document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
                     document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
                     document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
                  }
               }
            });
         });
      } else {
         landing[sectionOrder].components.forEach((maincomponent, i) => {
            maincomponent.subcomponents.forEach((component, j) => {
               if (component.slug === slug) {
                  landing[sectionOrder].components[i].subcomponents && landing[sectionOrder].components[i].subcomponents.splice(j, 1);
                  this.setState({ landing });
                  if (document.querySelectorAll(".SectionComponent")) {
                     document.querySelectorAll(".SectionComponent").forEach(el => el.style.display = "none");
                     document.querySelectorAll(".component_icon_up").forEach(el => el.style.display = "none");
                     document.querySelectorAll(".component_icon_down").forEach(el => el.style.display = "block");
                  }
               }
            });
         });
      }
   }
   //----------------------------------------------------------------------------------------
   //--------- Duplicate Component
   handleDuplicateComponent = (sectionSlug, slug, value, props_name, undoRedo = null) => {
      this.state.undo.push(JSON.stringify(this.state.landing))
      let landing = [...this.state.landing];
      let currentSection = landing.filter((sections) => sections.section.slug === sectionSlug);
      currentSection && currentSection[0].components.forEach((maincomponent, i) => {
         maincomponent.subcomponents.forEach((component, j) => {
            if (component.slug === slug) {
               let randomSlug = 'miestro-' + new Date().getTime() + Math.floor(Math.random() * 10000)
               let duplicatedComponent = JSON.parse(JSON.stringify(component));
               duplicatedComponent.slug = randomSlug;
               currentSection[0].components[i].subcomponents && currentSection[0].components[i].subcomponents.splice(j + 1, 0, duplicatedComponent);
               this.setState({ landing: landing });
            }
         });
      });
   }
   //----------------------------------------------------------------------------------------
   //  PREVIEW SECTION
   handlePreviewSection = (section_default_json, section_default_slug = null, currentIndex = -1) => {
      let landing = this.state.landing;
      this.setState({ isSectionRemoved: false });
      section_default_json.section.props.preview = true;
      if (currentIndex === -1) {
         landing.splice(this.state.landing.length - 1, 0, section_default_json);
      } else {
         landing.splice(currentIndex + 1, 0, section_default_json);
      }

      // ADD SECTION INTO UNDO ARRAY
      let undo = this.state.undo;
      let currentLanding = JSON.parse(JSON.stringify(this.state.landing));
      this.setState({ landing: currentLanding }, () => {
         // SCROLL TO NEW ADDED SECTION
         var added_section = document.querySelector("#" + section_default_slug);

         if (!added_section) {
            const iframeElement = document.querySelector('iframe');

            const contentElement = iframeElement?.contentDocument || iframeElement?.contentWindow.document;
            added_section = contentElement?.body.querySelector(`#${section_default_slug}`) || added_section;

            document.body.style.overflowY = 'auto'
         } else {
            document.body.style.overflow = 'hidden'
         }


         if (added_section) {
            added_section.scrollIntoView({ behavior: "smooth" });
         } else {
            toast.error("Ooops something went wrong!");
         }
      });
   };
   //  REMOVE PREVIEW SECTION
   handleRemovePreviewSection = (currentIndex = -1) => {
      let landing = this.state.landing;
      if (this.state.isSectionRemoved) {
         return;
      }
      if (currentIndex === -1) {
         landing.splice(this.state.landing.length - 2, 1);

      } else {
         landing.splice(currentIndex + 1, 1);
      }

      // ADD SECTION INTO UNDO ARRAY
      let undo = this.state.undo;
      let currentLanding = JSON.parse(JSON.stringify(this.state.landing));
      this.setState({ landing: currentLanding });
   };
   //Settings functions below(lending_metas)
   handleUpdateSettings = (value, props_name) => {
      let landing_metas = { ...this.state.landing_metas };
      landing_metas[props_name] = value;
      this.setState({ landing_metas });
   };

   handleFaviconChange = e => {
      let landing_metas = { ...this.state.landing_metas };
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
         landing_metas.landing_favicon = e.target.result;
         this.setState({ landing_metas }, () => {
            let link =
               document.querySelector("link[rel*='icon']") ||
               document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = landing_metas.landing_favicon;
            document.getElementsByTagName("head")[0].appendChild(link);
         });
      };
      reader.readAsDataURL(file);
   };

   handleFaviconChangetoDefaul = () => {
      let landing_metas = { ...this.state.landing_metas };
      landing_metas.landing_favicon = "https://miestro-production.s3-us-west-2.amazonaws.com/landing/favico.ico";
      this.setState({ landing_metas }, () => {
         let link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
         link.type = "image/x-icon";
         link.rel = "shortcut icon";
         link.href = landing_metas.landing_favicon;
         document.getElementsByTagName("head")[0].appendChild(link);
      });
   }

   handleFontChange = props_name => (e, { value }) => {
      let landing_metas = { ...this.state.landing_metas };
      landing_metas[props_name] = value;
      this.setState({ landing_metas });
   };
   // End settings part
   //--------------------------------------------------------------------------------------------
   // Drag & Drop Elements
   handleElementOnDragEnd = (result) => {
      if (result.draggableId.includes("ImagePlatform") && result.destination.droppableId !== result.source.droppableId) {
         toast.error("Ooops you dropped outside the list!");
         return;
      }
      this.state.undo.push(JSON.stringify(this.state.landing))
      const { source, destination, draggableId } = result;
      let isPrint = true;
      // dropped outside the list
      if (!destination) {
         for (let i = 0; i < document.getElementsByClassName('Toastify__toast-body').length; i++) {
            if (document.getElementsByClassName('Toastify__toast-body')[i].innerHTML === 'Ooops you dropped outside the list!') {
               isPrint = false
            }
         }
         if (isPrint) {
            toast.error("Ooops you dropped outside the list!");
         }
         return;
      }

      if (source.droppableId === 'newSection') {
         if (this.state.landing.length === destination.index) return;

         const sectionData = dragAndDropSections.find(currentSection => currentSection.section.name === draggableId);

         if (!sectionData) return;

         const sectionNewSlug = slug();
         sectionData.section.slug = sectionNewSlug;

         if (sectionData.components) {
            sectionData.components.map(comp => comp.slug = slug());
         }

         this.handleAddSection(sectionData, sectionNewSlug, destination.index-1 );
         return;
      }

      if (destination && destination.droppableId === 'newElements') {
         return;
      }
      let componentType = Text;
      switch (draggableId) {
         case "Headline": componentType = Headline;
            break;
         case "Subheadline": componentType = Subheadline;
            break;
         case "Text": componentType = Text;
            break;
         case "Bullet": componentType = Bullet;
            break;
         case "Button": componentType = Button;
            break;
         case "Image": componentType = Image;
            break;
         case "Video": componentType = Video;
            break;
         case "Divider": componentType = Divider;
            break;
         case "FAQ": componentType = FaqElementData;
            break;
         case "CallAction": componentType = CallActionElementData;
            break;
         default:
      }
      let componentSlug = destination.droppableId.split('*') && destination.droppableId.split('*')[1];
      let sectionSlug = destination.droppableId.split('*') && destination.droppableId.split('*')[0];
      let landing = this.state.landing;
      let landingSection = landing.filter(sublanding => sublanding.section.slug === sectionSlug);
      let landingComponents = landingSection && landingSection[0].components.filter(component => component.slug === componentSlug);

      if (source.droppableId !== 'newElements') {
         if (source.droppableId === destination.droppableId) {
            if (source.index !== destination.index) {
               let draggableComponent = landingComponents[0].subcomponents[source.index];
               landingComponents[0].subcomponents.splice(source.index, 1);
               landingComponents[0].subcomponents.splice(destination.index, 0, draggableComponent);
            }
         } else {
            let sourceSectionSlug = source.droppableId.split('*') && source.droppableId.split('*')[0];
            let sourceComponentSlug = source.droppableId.split('*') && source.droppableId.split('*')[1];
            let sourceLandingSection = landing.filter(sublanding => sublanding.section.slug === sourceSectionSlug);
            let sourceLandingComponents = sourceLandingSection && sourceLandingSection[0].components.filter(component => component.slug === sourceComponentSlug);
            let sourceDraggableComponent = sourceLandingComponents[0].subcomponents[source.index];
            sourceLandingComponents[0].subcomponents.splice(source.index, 1);
            landingComponents[0].subcomponents.splice(destination.index, 0, sourceDraggableComponent);
         }

      } else {
         landingComponents[0].subcomponents.splice(destination.index, 0, componentType());
      }
      this.setState({ landing: landing });
   }
   ////////////////////////////
   // REORDER SECTIONS
   handleOnDragEnd = res => {
      const { source, destination } = res;
      let isPrint = true;
      // dropped outside the list
      if (!destination) {
         for (let i = 0; i < document.getElementsByClassName('Toastify__toast-body').length; i++) {
            if (document.getElementsByClassName('Toastify__toast-body')[i].innerHTML === 'Ooops you dropped outside the list!') {
               isPrint = false
            }
         }
         if (isPrint) {
            toast.error("Ooops you dropped outside the list!");
         }
         return;
      }

      if (source.index === destination.index) return;

      this.handleReorder(source.index, destination.index);
   };

   handleReorder = (startIndex, endIndex) => {
      this.state.undo.push(JSON.stringify(this.state.landing))
      let landing = { ...this.state.landing };
      let undo;
      let currentLanding;
      if (landing[startIndex] && landing[endIndex]) {
         // CHECK AND ADD INITIAL LANDING TO UNDO ARRAY WHEN CHANGE IS FIRST TIME
         if (!this.state.firstRender) {
            undo = this.state.undo;
            currentLanding = {
               currentLanding: JSON.parse(JSON.stringify(landing)),
               slug: 'handleReorder',
               props_name: 'handleReorder',
               function: 'handleReorder',
            };
            this.setState({ firstRender: true });
         }
         // CHECK IF NEW CLICKED COMPONENT IS DIFFERENT OR PROPS NAME IS DIFFERENT TO ADD NEW OBJECT TO UNDO ARRAY
         if (this.state.undo[this.state.undo.length - 1].slug !== 'handleReorder' || this.state.undo[this.state.undo.length - 1].props_name !== 'handleReorder') {
            let undo = this.state.undo;
            let currentLanding = {
               currentLanding: JSON.parse(JSON.stringify(landing)),
               slug: 'handleReorder',
               props_name: 'handleReorder',
               function: 'handleReorder',
            };
         }
         // CHECK AND ADD INITIAL LANDING TO UNDO ARRAY WHEN CHANGE IS FIRST TIME
         const result = this.state.landing;
         const [removed] = result.splice(startIndex, 1);
         result.splice(endIndex, 0, removed);
         landing = { ...this.state.landing };
         landing = Object.values(landing);
         landing.map((section, i) => {
            section.section.props.order = i;
         });
         // ADD LANDING INTO UNDO ARRAY
         undo = this.state.undo;
         landing = Object.values(landing);
         currentLanding = {
            currentLanding: JSON.parse(JSON.stringify(landing)),
            slug: 'handleReorder',
            props_name: 'handleReorder',
            function: 'handleReorder',
         };
         this.setState({ landing });
      };
   };

   handleCheckoutCourses = () => {
      return {
         checkout_courses: this.state.checkout_courses,
         siteUUid: this.state.siteUUid,
         user_id: this.state.user_id,
      }

   }

   handleCheckoutOldCourseId = () => {
      return this.state.course_id;
   }

   handleEditableSectionOrder = (editableSectionOrder) => {
      this.setState({ editableSectionOrder });
   }

   handleEditableSectionOrderValue = () => {
      return this.state.editableSectionOrder;
   }

   handleConnectLandingWithDomain = (id) => {
      const domainUrl = getApiUrl()
      const { id: landingId } = this.state
      const url = getUrl()
      let token = getQueryParams('token', url);
      axios(
         {
            method: 'post',
            url: `${domainUrl}/api/v1/landings/${landingId}/connect/domain/${id}`,
            headers: {
               'Authorization': `Bearer ${token}`,
            },
         }
      ).then((response) => {
         if (response) {
            this.setState({ customDomain: id })
         }
      }).catch(error => {
         console.log(error)
      })
   }

   handleChangeViewMode(type) {
      document.body.style.overflowY = type
   }

   handleDisconnectLandingWithDomain = () => {
      const { customDomain } = this.state
      if (customDomain) {
         const domainUrl = getApiUrl()
         const { id: landingId } = this.state
         const url = getUrl()
         let token = getQueryParams('token', url);
         axios(
            {
               method: 'post',
               url: `${domainUrl}/api/v1/landings/${landingId}/disconnect/domain/${customDomain}`,
               headers: {
                  'Authorization': `Bearer ${token}`,
               },
            }
         ).then((response) => {
            if (response) {
               this.setState({ customDomain: null })
            }
         }).catch(error => {
            console.log(error)
         })
      }
   }

   handleChangeLandingInputs = (name, value) => {
      switch (name) {
         case 'landingTitle':
            this.setState({ landingTitle: value })
            break
         case 'landingUrlType':
            if (value === 'custom') {
               this.handleDisconnectLandingWithDomain()
            }
            this.setState({ landingUrlType: value })
            break
         case 'customDomain':
            this.handleConnectLandingWithDomain(value)
            break
         default:
            this.setState({ landingNewUrl: value })
      }
   }

   getAllHandleFunctions = () => {
      return {
         //Settings
         handleFaviconChange: this.handleFaviconChange,
         handleFaviconChangetoDefaul: this.handleFaviconChangetoDefaul,
         handleFontChange: this.handleFontChange,
         handleUpdateSettings: this.handleUpdateSettings,
         //End
         //Sections
         handleSectionOptions: this.handleSectionOptions,
         handleUpdateProps: this.handleUpdateProps,
         handleUpdateLanding: this.handleUpdateLanding,
         handleAddSection: this.handleAddSection,
         handleUndoRedo: this.handleUndoRedo,
         handlePreviewSection: this.handlePreviewSection,
         handleRemovePreviewSection: this.handleRemovePreviewSection,
         //End
         //Reorder functional
         handleOnDragEnd: this.handleOnDragEnd,
         handleChangeLandingInputs: this.handleChangeLandingInputs,
         handleElementOnDragEnd: this.handleElementOnDragEnd,
         //End
         //Add Link Component
         handleAddLinkComponent: this.handleAddLinkComponent,
         handleAddLinkSubComponent: this.handleAddLinkSubComponent,
         handleDeleteComponent: this.handleDeleteComponent,
         handleDuplicateComponent: this.handleDuplicateComponent,
         //
         handleCheckoutCourses: this.handleCheckoutCourses,
         handleCheckoutOldCourseId: this.handleCheckoutOldCourseId,
         handleEditableSectionOrderValue: this.handleEditableSectionOrderValue,
         //End
      };
   };
   render() {
      const scroll_to_options = [];
      let linkComponentCount = 0;
      if (this.state.landing && this.state.landing[0].components && this.state.landing[0].components[1] && this.state.landing[0].components[1].subcomponents) {
         linkComponentCount = this.state.landing && this.state.landing[0].components[1].subcomponents.filter(subcomponent => (subcomponent.type === 'LinkMi' && subcomponent.props.deleted !== true)).length;
      } else {
         linkComponentCount = this.state.landing && this.state.landing[0].components.filter(component => (component.type === 'LinkMi' && component.props.deleted !== true)).length;
      }
      this.state.landing && this.state.landing.map((section) => {
         section.section.props.visibility && scroll_to_options.push({ text: section.section.name, value: section.section.slug })
      })
      let { isLoading } = this.state;
      return (
         !isLoading ?
            <div className="myApp">
               <AppContext.Provider
                  value={{
                     state: this.state,
                     functions: this.getAllHandleFunctions(),
                  }}
               >
                  {this.state.landing && (
                     <AppContext.Consumer>
                        {({ state, functions }) => (
                           <Fragment>
                              {!this.state.loaded &&
                                 <div id="loader">
                                    <LoaderSpinner />
                                    {/* <Loader type='Puff' color='#7cb740' height={120} width={120} /> */}
                                 </div>
                              }
                              <ToastContainer autoClose={3000} hideProgressBar={true} closeOnClick />
                              <EditorMi
                                 // params={state}
                                 handleChangeViewMode={this.handleChangeViewMode}
                                 page={state.landing_metas.landing_name ?
                                    state.landing_metas.landing_name : state.landing_name}
                                 landing={state.landing}
                                 landing_metas={state.landing_metas}
                                 landing_prices={state.landing_prices}
                                 landing_courses={state.landing_courses}
                                 landingTitle={state.landingTitle}
                                 authUserRole={state.authUserRole}
                                 customDomain={this.state.customDomain}
                                 previewMode={state.previewMode}
                                 scroll_to_options={scroll_to_options}
                                 domains={this.state.domains}
                                 course_id={state.course_id}
                                 landingUrl={state.landingUrl}
                                 landingNewUrl={state.landingNewUrl}
                                 customLinks={state.customLinks}
                                 landingUrlType={state.landingUrlType}
                                 terms={state.terms}
                                 privacy={state.privacy}
                                 handleUpdateTempLanding={this.handleUpdateTempLanding}
                                 isModeAdmin={this.state.isModeAdmin}
                                 defaultLanding={getAllLandingDefaults().land_13["landing"]}
                                 course={this.state.course}
                                 dragAndDropLanding={state.landing_metas.dragAndDropLanding}
                                 linkComponentCount={linkComponentCount}
                                 checkout_courses={state.checkout_courses}
                                 user_id={state.user_id}
                                 siteUUid={state.siteUUid}
                                 handleEditableSectionOrder={this.handleEditableSectionOrder}
                              />
                           </Fragment>
                        )}
                     </AppContext.Consumer>
                  )}
               </AppContext.Provider>
            </div> : <div id="loader">
               <LoaderSpinner />
            </div>
      );
   }
}

export default App;
